import "./App.css";
import SecuredRouter from "./components/router/SecuredRouter";

function App() {
  return (
    <>
      <SecuredRouter></SecuredRouter>
    </>
  );
}

export default App;
